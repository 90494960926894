//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-864:_8168,_8116,_2300,_6712,_3488,_7616,_544,_5760;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-864')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-864', "_8168,_8116,_2300,_6712,_3488,_7616,_544,_5760");
        }
      }catch (ex) {
        console.error(ex);
      }